import {Actions, createEffect, ofType} from '@ngrx/effects';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NGXLogger} from 'ngx-logger';
import {Injectable} from '@angular/core';
import {FirmenService} from '../../openapi/partner-openapi';
import {FirmaEntitiesActions} from '../actions/firma-entities.actions';
import {catchError, concatMap, delay, filter, map, retry, switchMap} from 'rxjs/operators';
import {of, take} from 'rxjs';
import {mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';
import {Router} from '@angular/router';
import {SentryActions} from '../actions/sentry.actions';
import {FirmaEntitiesSelectors} from '../selectors/firma-entities.selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {FirmaEditActions} from '../actions/firma-edit.actions';
import {BankkontoService as ZahlungBankkontoService} from '../../openapi/zahlung-openapi';
import {BetriebService, EinstellungenService} from '../../openapi/fakturierung-openapi';


@Injectable()
export class FirmaEntitiesEffects {

  constructor(
    private actions$: Actions,
    private firmenService: FirmenService,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private router: Router,
    private store: Store<AppState>,
    private zahlungBankkontoService: ZahlungBankkontoService,
    private einstellungenService: EinstellungenService,
    private betriebService: BetriebService,
  ) {
  }

  /**
   * Effekt zum Laden eines Absenders anhand der Id, sofern es noch nicht im Store vorhanden ist.
   */
  readonly loadFirmaByIdIfAbsent$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.loadFirmaByIdIfAbsent),
      concatMap(action => this.store.select(FirmaEntitiesSelectors.firmaById(action.firmaId)).pipe(
        take(1),
        map(firmaDto => ({action, firmaDto}))
      )),
      filter(data => !data.firmaDto),
      map(data => FirmaEntitiesActions.readFirma({
        firmaId: data.action.firmaId,
      })),
    ),
  );

  /**
   * Lädt die Firma zur Id vom Service.
   */
  readonly readFirma$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.readFirma),
      switchMap(({
                   firmaId
                 }) => {
        return this.firmenService.readFirma(firmaId).pipe(
          map(firmaDto => {

            this.logger.debug(
              'read firma succeeded. firmaId: ',
              firmaId,
            );

            return FirmaEntitiesActions.readFirmaSuccess({
              firmaDto,
            });
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            concatMap(error => {
              this.logger.error(
                'read firma failed. error: ',
                error,
              );

              return [
                FirmaEntitiesActions.readFirmaFailure({
                  error: error,
                }),
                SentryActions.captureException({
                  error,
                  extras: {}
                }),
              ];
            }),
          ))
        );
      }),
    ),
  );

  /**
   * Error-Handling für das Laden einer Firma.
   */
  readonly readFirmaFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.readFirmaFailure),
      map(({
             error,
           }) => {

        let errorMsg = '';

        switch (error.status) {
          case 403: {
            errorMsg = 'Fehlende Berechtigungen für das Laden des Betriebs. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404: {
            errorMsg = 'Betrieb nicht gefunden. Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 409: {
            // INFO: Bei fehlenden Rechten, wird der Anwender auf die Betrieb-Not-Found-Seite weitergeleitet.
            this.router.navigateByUrl('betrieb-not-found');
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden des Betriebs. Bitte probiere es später erneut.';
          }
        }

        if (errorMsg) {
          this.snackbar.open(
            errorMsg,
            undefined,
            {
              duration: 5000,
            });
        }
      }),
    ), {dispatch: false}
  );

  /**
   * Lädt alle Firmen des Akteurs vom Service.
   */
  readonly readFirmen$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.readFirmen),
      switchMap(() => {
        return this.firmenService.readFirmenExtendedList().pipe(
          map(firmenDtos => {

            this.logger.debug(
              'read firmen succeeded',
            );

            // INFO: Sollte die Liste leer sein, hat der Anwender nicht die nötigen Berechtigungen.
            if (firmenDtos.length === 0) {
              this.router.navigateByUrl('betrieb-not-found');
            }

            return FirmaEntitiesActions.readFirmenSuccess({
              firmenDtos,
            });
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'read firmen failed. error: ',
                error,
              );

              return FirmaEntitiesActions.readFirmenFailure({
                error: error,
              });
            }),
          ))
        );
      }),
    ),
  );

  /**
   * Error-Handling für das Laden von Firmen.
   */
  readonly readFirmenFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.readFirmenFailure),
      map(({
             error,
           }) => {

        let errorMsg = '';

        switch (error.status) {
          case 403: {
            errorMsg = 'Fehlende Berechtigungen für das Laden der Betriebe. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404: {
            errorMsg = 'Keine Betriebe nicht gefunden. Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden der Betriebe. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
          });
      }),
    ), {dispatch: false}
  );

  /**
   * Aktualisieren einer Firma.
   */
  readonly updateFirma$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.updateFirma),
      switchMap(({
                   firmaId,
                   requestDto,
                 }) => {

        return this.firmenService.updateFirma(firmaId, requestDto).pipe(
          concatMap(() => {
            this.logger.debug(
              'update firma succeeded',
            );

            this.snackbar.open(
              'Betrieb erfolgreich aktualisiert.',
              undefined,
              {
                duration: 5000,
              });

            // INFO: Nach dem Aktualisieren wird der Anwender auf die Übersichtsseite weitergeleitet.
            this.router.navigateByUrl('overview');

            // INFO: Nach dem Aktualisieren wird die Firma neu geladen.
            return [
              FirmaEntitiesActions.readFirma({firmaId}),
              FirmaEntitiesActions.updateFirmaSuccess(),
            ];
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            concatMap(error => {
              this.logger.error(
                'update firma failed. error: ',
                error,
                'firmaId: ',
                firmaId,
              );

              return [
                FirmaEntitiesActions.updateFirmaFailure({
                  error: error,
                }),
                SentryActions.captureException({
                  error,
                  extras: {}
                }),
              ];
            }),
          ))
        );
      }),
    ),
  );

  /**
   * Error-Handling für das Aktualisieren einer Firma.
   */
  readonly updateFirmaFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.updateFirmaFailure),
      map(({
             error,
           }) => {

        let errorMsg = '';

        switch (error.status) {
          case 403: {
            errorMsg = 'Fehlende Berechtigungen für das Aktualisieren des Betriebs. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404: {
            errorMsg = 'Benötigte Daten für das Aktualisieren des Betriebs nicht gefunden. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 409: {
            errorMsg = error.error + ' ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Aktualisieren des Betriebs. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
          });
      }),
    ), {dispatch: false}
  );

  /**
   * Aktualisieren einer Firma im Rahmen der Fakturierungs-Integration.
   *
   * Schritt 1 / 4 - Aktualisieren der Stammdaten der Firma.
   */
  readonly updateFirmaFakturierungStep1UpdateStammdata$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.updateFirmaFakturierungStep1UpdateStammdata),
      switchMap(({
                   firmaId,
                   firmaUpdateRequestDto,
                   bankkontoCreateRequestDto,
                   einstellungenRequestDTO,
                 }) => {

        return this.firmenService.updateFirma(firmaId, firmaUpdateRequestDto).pipe(
          map(() => {
            this.logger.debug(
              'update firma data succeeded',
            );

            // INFO: Nach dem Aktualisieren findet das Handling für das Bankkonto statt.
            return FirmaEntitiesActions.updateFirmaFakturierungStep2HandleBankkonto({
              firmaId,
              bankkontoCreateRequestDto,
              einstellungenRequestDTO,
            });
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            concatMap(error => {
              this.logger.error(
                'update firma failed. error: ',
                error,
                'firmaId: ',
                firmaId,
              );

              return [
                FirmaEntitiesActions.updateFirmaFailure({
                  error: error,
                }),
                SentryActions.captureException({
                  error,
                  extras: {}
                }),
              ];
            }),
          ))
        );
      }),
    ),
  );

  /**
   * Aktualisieren einer Firma im Rahmen der Fakturierungs-Integration.
   *
   * Schritt 2 / 4 - Handling für das Ermitteln / Erstellen des Bankkontos.
   */
  readonly updateFirmaFakturierungStep2HandleBankkonto$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.updateFirmaFakturierungStep2HandleBankkonto),
      switchMap(({
                   firmaId,
                   bankkontoCreateRequestDto,
                   einstellungenRequestDTO,
                 }) => {
        if (bankkontoCreateRequestDto) {
          // INFO: Ist ein BankkontoRequestDTO vorhanden, so muss erst ein Bankkonto erstellt werden.

          return this.zahlungBankkontoService.createBankkonto(firmaId, bankkontoCreateRequestDto).pipe(
            switchMap(bankkontoDto => {
              this.logger.debug(
                'create bankkonto succeeded.',
              );

              return [
                FirmaEditActions.readBankkonten({firmaId}),
                FirmaEntitiesActions.updateFirmaFakturierungStep3UpdateConfiguration({
                  firmaId,
                  einstellungenRequestDTO: {
                    ...einstellungenRequestDTO,
                    bankkontoId: bankkontoDto.id,
                  },
                }),
              ];
            }),
            catchError(error => of(error).pipe(
              mappedHttpErrorResponseOperator(error),
              map(error => {
                this.logger.error(
                  'create bankkonto failed. error: ',
                  error,
                );

                return FirmaEntitiesActions.updateFirmaFakturierungStep2Failure({
                  error: error,
                });
              }),
            )),
          );
        } else {
          // INFO: Ist kein BankkontoRequestDTO vorhanden, können die Konfigurationen direkt aktualisiert werden.

          return of(FirmaEntitiesActions.updateFirmaFakturierungStep3UpdateConfiguration({
            firmaId,
            einstellungenRequestDTO,
          }));
        }
      }),
    ),
  );

  readonly createBankkontoFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.updateFirmaFakturierungStep2Failure),
      map(({error}) => {

        let errorMsg = '';

        switch (error.status) {
          case 404: {
            errorMsg = 'Benötigte Daten für das Erstellen des Bankkontos nicht gefunden. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 409: {
            errorMsg = 'Das Bankkonto existiert bereits. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          default: {
            errorMsg = 'Das Bankkonto konnte nicht erstellt werden. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
          });
      }),
    ),
    {dispatch: false},
  );

  /**
   * Schritt 3 / 4 - Aktualisieren der Konfiguration für die Einstellungen der Fakturierung.
   *
   * Aktualisiert das Einstellungen-Objekt einer Firma im Rahmen der Fakturierungs-Integration.
   */
  readonly updateFirmaFakturierungStep3UpdateConfiguration$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.updateFirmaFakturierungStep3UpdateConfiguration),
      switchMap(({
                   firmaId,
                   einstellungenRequestDTO,
                 }) => {

        return this.einstellungenService.updateEinstellungenDefault(firmaId, einstellungenRequestDTO).pipe(
          delay(2000),
          retry({
            count: 3,
            delay: 1000,
            resetOnSuccess: false,
          }),
          map(() => {
            this.logger.debug(
              'update configuration succeeded',
            );

            return FirmaEntitiesActions.updateFirmaFakturierungStep4ReloadFakturierungBetrieb({
              firmaId,
            });
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'update configuration failed. error: ',
                error,
                'firmaId: ',
                firmaId,
              );

              return FirmaEntitiesActions.updateConfigurationFailure({
                error: error,
              });
            }),
          ))
        );
      }),
    ),
  );

  readonly updateConfigurationFailure = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.updateConfigurationFailure),
      map(({
             error,
           }) => {

        this.snackbar.open(
          'Fehler beim Aktualisieren des Betriebs. Bitte probiere es später erneut.',
          undefined,
          {
            duration: 5000,
          });
      }),
    ), {dispatch: false}
  );

  /**
   * Aktualisieren einer Firma im Rahmen der Fakturierungs-Integration.
   *
   * Schritt 4 / 4 - Nachladen der Firma in die Fakturierung-DB.
   *
   * Die Daten der Firma werden erst von der ADNOVA+ Oracle-DB in die Fakturierung-DB übertragen,
   * wenn das Laden dieser angestoßen wird. Aus diesem Grund muss dies,
   * im Rahmen des Speicher-Vorgangs angestoßen werden.
   */
  readonly updateFirmaFakturierungStep4ReloadFakturierungBetrieb$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.updateFirmaFakturierungStep4ReloadFakturierungBetrieb),
      switchMap(({
                   firmaId,
                 }) => {

        return this.betriebService.getBetrieb(firmaId).pipe(
          map(() => {
            this.logger.debug(
              'update betrieb at fakturierung-db succeeded',
            );

            return FirmaEntitiesActions.updateFirmaSuccess();
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'update betrieb at fakturierung-db failed. error: ',
                error,
                'firmaId: ',
                firmaId,
              );

              return FirmaEntitiesActions.updateFirmaFakturierungStep4Failure({
                error: error,
              });
            }),
          ))
        );
      }),
    ),
  );

  readonly updateFirmaFakturierungStep4Failure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEntitiesActions.updateFirmaFakturierungStep4Failure),
      map(({error}) => {

        let errorMsg = '';

        switch (error.status) {
          case 403: {
            errorMsg = 'Fehlende Berechtigungen zum Aktualisieren der Firma. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404: {
            errorMsg = 'Die zu aktualisierende Firma konnte nicht gefunden werden. ' +
              'Bitte Kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Aktualisieren des Betriebs. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
          });
      }),
    ),
    {dispatch: false},
  );
}
