import {createAction, props} from '@ngrx/store';
import {FirmaExtendedReadResponseDTO, FirmaUpdateRequestDTO} from '../../openapi/partner-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';
import {BankkontoCreateRequestDTO, BankkontoDTO as ZahlungBankkonto} from '../../openapi/zahlung-openapi';
import {BankkontoDTO, EinstellungenRequestDTO, KontaktDTO} from '../../openapi/fakturierung-openapi';


export class FirmaEntitiesActions {

  public static loadFirmaByIdIfAbsent = createAction(
    '[Firma Entities] Load firma by ID if absent.',
    props<{
      firmaId: string;
    }>(),
  );

  public static readFirma = createAction(
    '[Firma Entities] Read firma.',
    props<{
      firmaId: string,
    }>()
  );

  public static readFirmaSuccess = createAction(
    '[Firma Entities] Read firma successfully.',
    props<{
      firmaDto: FirmaExtendedReadResponseDTO,
    }>()
  );

  public static readFirmaFailure = createAction(
    '[Firma Entities] Read firma failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>()
  );

  public static readFirmen = createAction(
    '[Firma Entities] Read firmen.',
  );

  public static readFirmenSuccess = createAction(
    '[Firma Entities] Read firmen successfully.',
    props<{
      firmenDtos: FirmaExtendedReadResponseDTO[],
    }>()
  );

  public static readFirmenFailure = createAction(
    '[Firma Entities] Read firmen failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>()
  );

  public static updateFirma = createAction(
    '[Firma Entities] Update firma.',
    props<{
      firmaId: string,
      requestDto: FirmaUpdateRequestDTO,
    }>()
  );

  public static updateFirmaSuccess = createAction(
    '[Firma Entities] Update firma succeeded .',
  );

  public static updateFirmaFailure = createAction(
    '[Firma Entities] Update firma failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static resetUpdateFirmaSuccessfulState = createAction(
    '[Firma Entities] Resets the update-firma-successful state.',
  );


  public static updateFirmaFakturierungStep1UpdateStammdata = createAction(
    '[Firma Entities] Update firma for fakturierung integration.',
    props<{
      firmaId: string,
      firmaUpdateRequestDto: FirmaUpdateRequestDTO,
      bankkontoCreateRequestDto: BankkontoCreateRequestDTO | undefined,
      einstellungenRequestDTO: EinstellungenRequestDTO,
    }>()
  );

  public static updateFirmaFakturierungFailure = createAction(
    '[Firma Entities] Update firma for fakturierung integration failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static updateFirmaFakturierungStep2HandleBankkonto = createAction(
    '[Firma Entities] Handle create bankkonto.',
    props<{
      firmaId: string,
      bankkontoCreateRequestDto: BankkontoCreateRequestDTO | undefined,
      einstellungenRequestDTO: EinstellungenRequestDTO,
    }>(),
  );

  public static createBankkontoSuccess = createAction(
    '[Firma Entities] Create bankkonto success.',
    props<{
      response: ZahlungBankkonto,
    }>(),
  );

  public static updateFirmaFakturierungStep2Failure = createAction(
    '[Firma Entities] Create bankkonto failure.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static updateFirmaFakturierungStep3UpdateConfiguration = createAction(
    '[Firma Entities] Update firma fakturierung configuration.',
    props<{
      firmaId: string,
      einstellungenRequestDTO: EinstellungenRequestDTO,
    }>(),
  );

  public static updateConfigurationFailure = createAction(
    '[Firma Entities] Update firma fakturierung configuration failure.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static updateFirmaFakturierungStep4ReloadFakturierungBetrieb = createAction(
    '[Firma Entities] Update fakturierung firma at database level .',
    props<{
      firmaId: string,
    }>(),
  );

  public static updateFirmaFakturierungStep4Failure = createAction(
    '[Firma Entities] Update fakturierung firma at database level failed .',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

}
