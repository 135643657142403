import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {catchError, map, switchMap} from 'rxjs/operators';
import {FirmaEditActions} from '../actions/firma-edit.actions';
import {BankkontoService as FakturaBankkontoService, EinstellungenService} from '../../openapi/fakturierung-openapi';
import {BankkontoService as ZahlungBankkontoService, BankService} from '../../openapi/zahlung-openapi';
import {of} from 'rxjs';
import {mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';
import {FirmaEntitiesActions} from '../actions/firma-entities.actions';


@Injectable()
export class FirmaEditEffects {

  constructor(
    private actions$: Actions,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private einstellungenService: EinstellungenService,
    private fakturaBankkontoService: FakturaBankkontoService,
    private bankService: BankService,
  ) {
  }

  readonly readEinstellungen$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEditActions.readEinstellungen),
      switchMap((props) => {
        return this.einstellungenService.getEinstellungenDefault(props.firmaId).pipe(
          map(einstellungenResponseDto => {
            this.logger.debug(
              'read einstellungen succeeded.',
            );

            return FirmaEditActions.updateEinstellungenRequestDto({
              einstellungenResponseDto,
            });
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'read einstellungen failed. error: ',
                error,
              );

              return FirmaEditActions.readEinstellungenFailure({
                error: error,
              });
            }),
          ))
        );
      }),
    ),
  );

  readonly readEinstellungenFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEditActions.readEinstellungenFailure),
      map(({error}) => {
        this.snackbar.open(
          'Fehler bei dem Laden der Kontaktdaten-Informationen. Bitte probiere es später erneut.',
          undefined,
          {
            duration: 5000,
          });
      }),
    ),
    {dispatch: false},
  );

  readonly readBankkonten$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEditActions.readBankkonten),
      switchMap((props) => {
        return this.fakturaBankkontoService.readBankkonten(props.firmaId).pipe(
          map((bankkontoPageDto) => {
            this.logger.debug(
              'read bankkontoPageDto succeeded.',
            );

            return FirmaEditActions.readBankkontenSuccess({
              bankkonten: bankkontoPageDto.content,
            });
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'read bankkonten failed. error: ',
                error,
              );

              return FirmaEditActions.readBankkontenFailure({
                error: error,
              });
            }),
          ))
        );
      }),
    ),
  );

  readonly readBankkontenFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEditActions.readBankkontenFailure),
      map(({error}) => {
        this.snackbar.open(
          'Fehler bei dem Laden der Bankverbindung. Bitte probiere es später erneut.',
          undefined,
          {
            duration: 5000,
          });
      }),
    ),
    {dispatch: false},
  );

  readonly readBankinformation$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEditActions.readBankinformation),
      switchMap(({iban}) => {
        return this.bankService.readBank(iban).pipe(
          map((bankDto) => {
            this.logger.debug(
              'read bankinformation succeeded.',
            );

            return FirmaEditActions.updateSelectedBankInformation({
              bankkontoDto: {
                iban,
                bic: bankDto.bic,
                bankbezeichnung: bankDto.bezeichnung,
                id: '',
                kontoinhaber: '',
              }
            });
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
                this.logger.error(
                  'read bankinformation failed. error: ',
                  error,
                );

                return FirmaEditActions.readBankinformationFailure({
                  error: error,
                });
              }
            ),
          ))
        );
      }),
    ),
  );

  readonly readBankinformationFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FirmaEditActions.readBankinformationFailure),
      map(({error}) => {
        this.snackbar.open(
          'Fehler bei dem Laden der Bank-Informationen. Bitte probiere es später erneut.',
          undefined,
          {
            duration: 5000,
          });
      }),
    ),
    {dispatch: false},
  );

}
