import {inject, Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {from} from 'rxjs';
import {PortalMessageBroker} from '../portal/portal-message-broker';
import {Store} from '@ngrx/store';
import {AppState} from '../store/states/app.state';
import {AppContextActions} from '../store/actions/app-context.actions';
import {Router} from '@angular/router';

export interface ExampleDataV1 {
  readonly exampleId: string;
}

export interface PopupOpenDataV1 {
  readonly betriebId: string;
}

export interface UpdatedBetriebDataV1 {
  readonly betriebId: string;
}

@Injectable({
  providedIn: 'root'
})
export class IntentActionService {

  private _store = inject(Store<AppState>);
  private _router = inject(Router);

  private readonly exampleV1 = 'de.just-farming:example:example';

  private readonly betriebUpdatedIdV1 = 'de.just-farming:betriebsinformationen:betrieb.updated';

  private readonly editPopupOpenFakturierungV1 = 'de.just-farming:fakturierung:popup.edit-betriebsinformationen';

  constructor(
    private logger: NGXLogger,
    private portalMessageBroker: PortalMessageBroker,
  ) {
    if (this.isRunningInPortal()) {
      this.portalMessageBroker.registerIntentCallback(this.exampleV1, '1', (data => {
        this.handleExampleV1(data);
      }));

      this.portalMessageBroker.registerIntentCallback(this.editPopupOpenFakturierungV1, '1', (data => {
        this.handleEditBetriebPopupV1(data);
      }));

      this.portalMessageBroker.allIntentCallbackRegistered();
    }
  }

  /**
   * Prüft, ob die App im Portal läuft
   */
  public isRunningInPortal(): boolean {
    return this.portalMessageBroker.isRunningInPortal();
  }

  /**
   * Schließt ein Intent Popup
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   */
  public doCloseDialogV1(): void {
    this.doClose();
  }

  private doClose(): void {
    if (!this.isRunningInPortal()) {
      throw new Error('app is not running in portal');
    }

    const promise = this.portalMessageBroker.closeIntent();
    from(promise)
      .subscribe(
        value => {
          this.logger.debug('IntentActionService.doClose(): handles successful', value);
        },
        error => {
          this.logger.debug('IntentActionService.doClose(): closing failed', error);
        },
      );
  }

  private doEmit(
    intentId: string,
    intentVersion: string,
    data: any,
  ): void {
    if (!this.isRunningInPortal()) {
      throw new Error('app is not running in portal');
    }

    const promise = this.portalMessageBroker.emitIntent(intentId, intentVersion, data);
    from(promise)
      .subscribe(
        value => {
          this.logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' handles successful', value);
        },
        error => {
          this.logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' dispatch failed', error);
        },
      );
  }

  public doExampleV1(
    data: ExampleDataV1,
  ): void {
    this.doEmit(this.exampleV1, '1', data);
  }

  handleExampleV1(
    data: ExampleDataV1
  ): void {
    // TODO impl
  }

  handleEditBetriebPopupV1(
    data: PopupOpenDataV1
  ): void {
    const betriebId = data.betriebId;

    this._router.navigate(
      [
        'fakturierung/edit/inhaber/' + betriebId
      ]
    ).then(() => {
      this._store.dispatch(AppContextActions.setPopupMode({popupMode: true}));
    });
  }

  /**
   * Informiert andere Apps über die Aktualisierung eines Betriebs per Broadcast Hintergrund-Intent
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doBetriebUpdatedV1(
    data: UpdatedBetriebDataV1,
  ): void {
    this.doEmit(this.betriebUpdatedIdV1, '1', data);
  }
}
