import {Component, DestroyRef, inject} from '@angular/core';
import {MatomoTracker} from '@ngx-matomo/tracker';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Observable, take} from 'rxjs';
import {IntentActionService} from './services/intent-action.service';
import {PortalMessageBroker} from './portal/portal-message-broker';
import {ContextInfoService, WellKnownJustFarmingProperties} from './portal/context-info.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private _destroyRef = inject(DestroyRef);
  private _portalMessageBroker = inject(PortalMessageBroker);
  private _oidcSecurityService = inject(OidcSecurityService);
  private _contextInfoService = inject(ContextInfoService);
  private _tracker = inject(MatomoTracker);
  private _intentActionService = inject(IntentActionService);

  runningInPortal$: Observable<boolean>;

  constructor() {
    this.runningInPortal$ = this._portalMessageBroker.runningInPortal$.pipe(
      takeUntilDestroyed(this._destroyRef),
    );

    this._contextInfoService.contextInfo$.pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(contextInfo => {
      let analytics = contextInfo?.properties.get(WellKnownJustFarmingProperties.ANALYTICS);
      if (analytics === true) {
        this._tracker.setConsentGiven();
      } else {
        this._tracker.forgetConsentGiven();
      }
    });
  }

  doLogout(): void {
    this._oidcSecurityService.logoff().pipe(take(1)).subscribe();
  }
}
