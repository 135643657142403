/**
 * Zahlung
 * Die API ermöglicht es, Zahlungen zu erstellen, zu aktualisieren, zu bearbeiten und zu löschen. Darüber hinaus können Zahlungen ausgeführt werden. Hierbei fungiert der Zahlung-Service als Schnittstelle zum Banking-Service. Der Banking-Service ist gekapselt für die Kommunikation mit unserem Dienstleister B+S zuständig. <br><br> Um eine Zahlung auszuführen muss zuerst mit `getToken` ein Token für den Zahlungsverkehr geholt werden. Dieses Token wird für die Kommunikation mit B+S benötigt. <br> Anschließend kann mit `getKontaktInfo` die Kontaktinformationen zu einem Login abgerufen werden. Hierbei kann es sein, dass ein Sicherheitsverfahren ausgewählt werden muss. In diesem Fall muss mit `setSicherheitsverfahren` das Sicherheitsverfahren gesetzt werden. <br> Dann kann `assignPayment` aufgerufen werden. In diesem Zuge wird die Zahlung durch B+S bei der Bank bekannt gemacht. Die Antwort von B+S wird dann an den Zahlung-Service zurückgegeben. Gegebenenfalls muss der Anwender eine starke Authentifizierung durchführen. <br> Anschließend kann die Zahlung mit `approvePayment` bestätigt werden. Soll die Zahlung doch nicht durchgeführt werden, kann sie mit `cancelPayment` abgelehnt werden. <br><br> Im Rahmen des Durchführens von Zahlung(en) wird ein Zahlungslauf erstellt, welcher diese protokolliert. <br><br> Die API bietet mit die Möglichkeit Bankkonten anzulegen und deren Kontostände zu aktualisieren. <br><br> Es kann nach nach Banken im SEPA Raum gesucht werden und die in der Vergangenheit verwendeten Banken können abgefragt werden. 
 *
 * The version of the OpenAPI document: ${project.version}
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { BankingFehlerResponseDTO } from '../model/models';
import { BankkontoCreateRequestDTO } from '../model/models';
import { BankkontoDTO } from '../model/models';
import { BankkontoFilterDTO } from '../model/models';
import { BankkontoPageableDTO } from '../model/models';
import { ErrorMessageDTO } from '../model/models';
import { SicherheitsverfahrenDTO } from '../model/models';
import { SicherheitsverfahrenResponseDTO } from '../model/models';
import { StarkeAuthentifizierungResponseDTO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class BankkontoService {

    protected basePath = 'https://api.adnova.de/zahlung-service';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Erstellen eines Bankkontos
     * @param inhaberId UUID die einen Inhaber identifiziert
     * @param bankkontoCreateRequestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBankkonto(inhaberId: string, bankkontoCreateRequestDTO: BankkontoCreateRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BankkontoDTO>;
    public createBankkonto(inhaberId: string, bankkontoCreateRequestDTO: BankkontoCreateRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BankkontoDTO>>;
    public createBankkonto(inhaberId: string, bankkontoCreateRequestDTO: BankkontoCreateRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BankkontoDTO>>;
    public createBankkonto(inhaberId: string, bankkontoCreateRequestDTO: BankkontoCreateRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling createBankkonto.');
        }
        if (bankkontoCreateRequestDTO === null || bankkontoCreateRequestDTO === undefined) {
            throw new Error('Required parameter bankkontoCreateRequestDTO was null or undefined when calling createBankkonto.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BankkontoDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/bankkonten`,
            bankkontoCreateRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ermittelt das Bankkonto welches zu der übergebene Iban gehört.
     * @param inhaberId UUID die einen Inhaber identifiziert
     * @param bankkontoIdentifier Uuid oder Iban zu einem Bankkonto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findBankkontoByIdentifier(inhaberId: string, bankkontoIdentifier: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BankkontoDTO>;
    public findBankkontoByIdentifier(inhaberId: string, bankkontoIdentifier: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BankkontoDTO>>;
    public findBankkontoByIdentifier(inhaberId: string, bankkontoIdentifier: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BankkontoDTO>>;
    public findBankkontoByIdentifier(inhaberId: string, bankkontoIdentifier: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling findBankkontoByIdentifier.');
        }
        if (bankkontoIdentifier === null || bankkontoIdentifier === undefined) {
            throw new Error('Required parameter bankkontoIdentifier was null or undefined when calling findBankkontoByIdentifier.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<BankkontoDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/bankkonten/${encodeURIComponent(String(bankkontoIdentifier))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lesen von Bankkonten
     * @param inhaberId UUID die einen Inhaber identifiziert
     * @param bankkontoFilter Objekt für die Suche von Bankkonten
     * @param bankkontoPageable Objekt für das OffSet, Limit und die Sortierung beim Laden von Bankkonten
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readBankkonten(inhaberId: string, bankkontoFilter: BankkontoFilterDTO, bankkontoPageable?: BankkontoPageableDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<BankkontoDTO>>;
    public readBankkonten(inhaberId: string, bankkontoFilter: BankkontoFilterDTO, bankkontoPageable?: BankkontoPageableDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<BankkontoDTO>>>;
    public readBankkonten(inhaberId: string, bankkontoFilter: BankkontoFilterDTO, bankkontoPageable?: BankkontoPageableDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<BankkontoDTO>>>;
    public readBankkonten(inhaberId: string, bankkontoFilter: BankkontoFilterDTO, bankkontoPageable?: BankkontoPageableDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling readBankkonten.');
        }
        if (bankkontoFilter === null || bankkontoFilter === undefined) {
            throw new Error('Required parameter bankkontoFilter was null or undefined when calling readBankkonten.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (bankkontoFilter !== undefined && bankkontoFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>bankkontoFilter, 'bankkontoFilter');
        }
        if (bankkontoPageable !== undefined && bankkontoPageable !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>bankkontoPageable, 'bankkontoPageable');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<BankkontoDTO>>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/bankkonten`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aktualisiert den Kontostand für das Bankkonto welches zum übergebenen Identifier gehört.
     * @param inhaberId UUID die einen Inhaber identifiziert
     * @param bankkontoIdentifier Uuid oder Iban zu einem Bankkonto
     * @param zahlungBankingToken Token für den Zahlungsverkehr
     * @param zahlungBankingRedirectUrl URL für die erfolgreiche TAN Eingabe (XS2A)
     * @param zahlungBankingFailureRedirectUrl URL für die nicht erfolgreiche TAN Eingabe (XS2A)
     * @param zahlungBankingClientIp IP des Clients
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateKontostand(inhaberId: string, bankkontoIdentifier: string, zahlungBankingToken: string, zahlungBankingRedirectUrl: string, zahlungBankingFailureRedirectUrl: string, zahlungBankingClientIp: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BankkontoDTO>;
    public updateKontostand(inhaberId: string, bankkontoIdentifier: string, zahlungBankingToken: string, zahlungBankingRedirectUrl: string, zahlungBankingFailureRedirectUrl: string, zahlungBankingClientIp: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BankkontoDTO>>;
    public updateKontostand(inhaberId: string, bankkontoIdentifier: string, zahlungBankingToken: string, zahlungBankingRedirectUrl: string, zahlungBankingFailureRedirectUrl: string, zahlungBankingClientIp: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BankkontoDTO>>;
    public updateKontostand(inhaberId: string, bankkontoIdentifier: string, zahlungBankingToken: string, zahlungBankingRedirectUrl: string, zahlungBankingFailureRedirectUrl: string, zahlungBankingClientIp: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling updateKontostand.');
        }
        if (bankkontoIdentifier === null || bankkontoIdentifier === undefined) {
            throw new Error('Required parameter bankkontoIdentifier was null or undefined when calling updateKontostand.');
        }
        if (zahlungBankingToken === null || zahlungBankingToken === undefined) {
            throw new Error('Required parameter zahlungBankingToken was null or undefined when calling updateKontostand.');
        }
        if (zahlungBankingRedirectUrl === null || zahlungBankingRedirectUrl === undefined) {
            throw new Error('Required parameter zahlungBankingRedirectUrl was null or undefined when calling updateKontostand.');
        }
        if (zahlungBankingFailureRedirectUrl === null || zahlungBankingFailureRedirectUrl === undefined) {
            throw new Error('Required parameter zahlungBankingFailureRedirectUrl was null or undefined when calling updateKontostand.');
        }
        if (zahlungBankingClientIp === null || zahlungBankingClientIp === undefined) {
            throw new Error('Required parameter zahlungBankingClientIp was null or undefined when calling updateKontostand.');
        }

        let headers = this.defaultHeaders;
        if (zahlungBankingToken !== undefined && zahlungBankingToken !== null) {
            headers = headers.set('zahlung-bankingToken', String(zahlungBankingToken));
        }
        if (zahlungBankingRedirectUrl !== undefined && zahlungBankingRedirectUrl !== null) {
            headers = headers.set('zahlung-bankingRedirectUrl', String(zahlungBankingRedirectUrl));
        }
        if (zahlungBankingFailureRedirectUrl !== undefined && zahlungBankingFailureRedirectUrl !== null) {
            headers = headers.set('zahlung-bankingFailureRedirectUrl', String(zahlungBankingFailureRedirectUrl));
        }
        if (zahlungBankingClientIp !== undefined && zahlungBankingClientIp !== null) {
            headers = headers.set('zahlung-bankingClientIp', String(zahlungBankingClientIp));
        }

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<BankkontoDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/bankkonten/${encodeURIComponent(String(bankkontoIdentifier))}/kontostand`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aktualisiert den Kontostand für das Bankkonto welches zum übergebenen Identifier gehört mit starker Authentifizierung. Diese Methode muss genutzt werden, wenn das Holen des Kontostandes ein Sicherheitsverfahren oder eine starke Authentifzierung gefordert hat. 
     * @param inhaberId UUID die einen Inhaber identifiziert
     * @param bankkontoIdentifier Uuid oder Iban zu einem Bankkonto
     * @param zahlungBankingToken Token für den Zahlungsverkehr
     * @param sicherheitsverfahrenDTO 
     * @param zahlungBankingTan Tan für den Zahlungsverkehr
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateKontostandStrong(inhaberId: string, bankkontoIdentifier: string, zahlungBankingToken: string, sicherheitsverfahrenDTO: SicherheitsverfahrenDTO, zahlungBankingTan?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BankkontoDTO>;
    public updateKontostandStrong(inhaberId: string, bankkontoIdentifier: string, zahlungBankingToken: string, sicherheitsverfahrenDTO: SicherheitsverfahrenDTO, zahlungBankingTan?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BankkontoDTO>>;
    public updateKontostandStrong(inhaberId: string, bankkontoIdentifier: string, zahlungBankingToken: string, sicherheitsverfahrenDTO: SicherheitsverfahrenDTO, zahlungBankingTan?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BankkontoDTO>>;
    public updateKontostandStrong(inhaberId: string, bankkontoIdentifier: string, zahlungBankingToken: string, sicherheitsverfahrenDTO: SicherheitsverfahrenDTO, zahlungBankingTan?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling updateKontostandStrong.');
        }
        if (bankkontoIdentifier === null || bankkontoIdentifier === undefined) {
            throw new Error('Required parameter bankkontoIdentifier was null or undefined when calling updateKontostandStrong.');
        }
        if (zahlungBankingToken === null || zahlungBankingToken === undefined) {
            throw new Error('Required parameter zahlungBankingToken was null or undefined when calling updateKontostandStrong.');
        }
        if (sicherheitsverfahrenDTO === null || sicherheitsverfahrenDTO === undefined) {
            throw new Error('Required parameter sicherheitsverfahrenDTO was null or undefined when calling updateKontostandStrong.');
        }

        let headers = this.defaultHeaders;
        if (zahlungBankingToken !== undefined && zahlungBankingToken !== null) {
            headers = headers.set('zahlung-bankingToken', String(zahlungBankingToken));
        }
        if (zahlungBankingTan !== undefined && zahlungBankingTan !== null) {
            headers = headers.set('zahlung-bankingTan', String(zahlungBankingTan));
        }

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<BankkontoDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/bankkonten/${encodeURIComponent(String(bankkontoIdentifier))}/kontostand/strong`,
            sicherheitsverfahrenDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
