import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { BankkontoService } from './api/bankkonto.service';
import { BelegService } from './api/beleg.service';
import { BelegContentService } from './api/beleg-content.service';
import { BetriebService } from './api/betrieb.service';
import { EinstellungenService } from './api/einstellungen.service';
import { KundeService } from './api/kunde.service';
import { LogoService } from './api/logo.service';
import { MengeneinheitService } from './api/mengeneinheit.service';
import { NachlaufzeileService } from './api/nachlaufzeile.service';
import { PositionService } from './api/position.service';
import { PreviewService } from './api/preview.service';
import { ProduktService } from './api/produkt.service';
import { UmsatzsteuerService } from './api/umsatzsteuer.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
